<template>
  <div id="server-error-modal" class="uk-flex-top" 
    uk-modal="bg-close: false; esc-close: false"
  >
    <div class="uk-modal-dialog uk-modal-danger uk-margin-auto-vertical">
      <button class="uk-modal-close-inside" type="button" @click="dismissError();" uk-close></button>
      <h2 class="uk-modal-title">{{ $t( 'Generic.Errors.ServerError' ) }}</h2>
      <p>{{ serverErrorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ServerErrorModal',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      serverError: state => state.venom && state.venom.serverError,
      serverErrorMessage: state => state.venom && state.venom.serverError || 'Server Error'
    })
  },
  watch: {
    serverError: function( newValue ) {
      if ( newValue ) {
        window.UIkit.modal('#server-error-modal').show();
      }
    }
  },
  methods: {
    dismissError: function() {
      this.$store.dispatch('clearError');
      window.UIkit.modal('#server-error-modal').hide();
      // this.$router.go(-1);
    }
  },
  mounted() {
  }
}
</script>
