<template>
  <div>
    <connection-modal />
    <server-error-modal />

    <!-- Page Wrapper -->
    <!-- Sidebar -->
    <div class="left-panel">
      <sidebar />
    </div>

    <!-- Page Content -->
    <div class="right-panel">

      <!-- Top Navigation -->
      <top-nav />
      
      <div id="content-wrapper" class="content-wrapper" uk-height-viewport="expand: true">
        <breadcrumb />
        <router-view />
      </div>
    </div>

    <footer-bar />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ConnectionModal from '@/components/modals/ConnectionModal.vue';
import ServerErrorModal from '@/components/modals/ServerErrorModal.vue';
import TopNav from '@/components/nav/TopNav.vue';
import Breadcrumb from '@/components/nav/Breadcrumb.vue';
import Sidebar from '@/components/nav/Sidebar.vue';
import FooterBar from '@/components/nav/FooterBar.vue';
import { Permissions } from '@/utils/enums';
import Notification from '@/components/util/Notification';

export default {
  name: 'AdminLayout',
  components: {
    ConnectionModal,
    ServerErrorModal,
    TopNav,
    Breadcrumb,
    Sidebar,
    FooterBar
  },
  data() {
    return {
      orgId: this.$route.params.id,
      windowWidth: window.innerWidth,
      notifications: 0
    }
  },
  computed: {
    ...mapGetters([
      'permissions',
      'organizations'
    ]),
    ...mapState({
      viewer: state => state.venom.org.viewer,
      user: state => state.venom.user
    }),
    permissionSet () {
      const permSet = new Set ()
      this.viewer?.permissions?.forEach(permission => {
        permSet.add(permission.type)
      })
      return permSet
    }
  },
  async mounted() {
    const promises = [this.$store.dispatch('getOrgPermissions', this.$route.params.id || this.$route.params.orgId )]
    if (!this.user?.id) {
      promises.push(this.$store.dispatch('getViewer'))
    }
    if (!this.organizations?.length) {
      promises.push(this.$store.dispatch('getAvailableOrgs'))
    }
    await Promise.all(promises)
    this.$nextTick(() => {
      const orgId = this.$route.params.id ? this.$route.params.id : this.$route.params.orgId || this.$router?.history?.current?.params?.id
      if (this.viewer?.role && orgId ) {
        this.$store.dispatch('selectOrganization', { orgId, admin: this.permissionSet.has(Permissions.ADMIN) });
      }
    })
    // Needs better feedback to the user
    if ( !this.$route.params.id ) {
      this.$store.dispatch('clearError');
      // this.$router.go(-1);
    }
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      if (  this.windowWidth < 1366 ) {
        if ( this.notifications === 0 ) {
          Notification.showNotification(this.$t('Generic.Labels.Warning'), this.$t('Generic.Messages.SupportedWindowWidth'), 'warn', 0);
          const notificationElements = document.querySelectorAll('.uk-notification-message');
          this.notifications = notificationElements?.length;
        }
      }
      else {
        this.notifications = 0;
      }
    };
  },
  methods: {
  }
}
</script>

<style lang="scss">
.left-panel {
  position: fixed;
  width: 250px;
}
.right-panel {
  min-height: 100vh;
  margin-left: 250px;
  padding-left: 0px;
}
.content-wrapper {
  padding: 0px 20px 60px 20px;
  // min-height: 100vh;
} 
.uk-notification-close {
  display: block;
  color: var(--white-color);
  right: 5px;
  top: 8px;
  margin-right: 5px;
}
.message {
  right: 10px;
}
.uk-notification-message .uk-grid-divider {
  margin-left: -80px;
}

</style>
