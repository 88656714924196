<template>
  <ul class="uk-breadcrumb uk-margin-top" v-if="showBreadCrumbs">
    <!-- <li><router-link :to="{ name: 'Dashboard' }" aria-label="Home"><i uk-icon="icon: home"></i></router-link></li> -->
    <li
      v-for="(crumb, i) in getCrumbList()"
      :key="i"
    >
      <router-link v-if="crumb" :to="getCrumbUrl(crumb)" class="uk-width-large uk-text-truncate cumb-link" style="max-width:200px!important"> <span class="uk-width-1-1 uk-text-truncate " >{{ getFormattedCrumbName(crumb) }}</span></router-link>
    </li>
  </ul>
  <div v-else class="uk-width-1-1 uk-margin-small"></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Breadcrumb',
  data() {
    return {
      crumbs: []
    }
  },
  computed: {
    routePath: function() {
      return this.$route.path;
    },
    ...mapGetters([
      'organizations'
    ]),
    ...mapState({
      org: state => state?.venom?.org || {},
      content: state => state?.venom?.org?.content,
      contentItem:state=> state?.venom?.org?.contentItem
    }),
    showBreadCrumbs () {
      if (this.organizations.length === 1 && this.$route.name === 'Dashboard') return false
      else return true
    }
  },
  methods: {
    getCrumbList (){
      return this.crumbs.filter((item)=>item)
    },
    getCrumbUrl: function(crumb) {
      const crumbPath = this.$route.path.split(crumb)[0];
      if ( crumbPath === '/' ) {
        if (this.organizations?.length > 1) {
          return { name: 'Organizations' }; 
        } else {
          return { name: 'Dashboard' }
        }
      }
      if ( this.$route.name === 'Collections' ||  this.$route.name === 'Content' ) {
        if (typeof crumb === 'object') {
          return { name: 'Content', params: { id: this.$route.params.id, propertyId: this.$route.params.propertyId, collectionId: crumb.id }}
        }
        const property = this.getProperty( crumb );
        if ( property && crumb === property.id ) {
          return `${this.$route.path.split(crumb)[0].concat(crumb)}/${property.i18nName.localized.value}/content`;
        } 
      }
      if (this.$route.name === 'ContentDetails') {
        if (typeof crumb === 'object') {
          return { name: 'Content', params: { id: this.$route.params.id, propertyId: this.$route.params.propertyId, collectionId: crumb.id }}
        }
        if (crumb.toLowerCase() === 'content') {
          if (this.content?.id) {
            return { name: 'Content', params: {  collectionId: this.content?.id, propertyId: this.$route.params.propertyId, id: this.org?.id } }
          } else {
            return '#'
          }
        } else {
          const property = this.getProperty(crumb)
          if (property && (crumb === property.id)) {
            return { name: 'Collections', params: { id: this.org?.id, propertyId: this.$route.params.propertyId, propertyName: property.i18nName.localized.value}}
          }
        }
      } else if (this.$route.name === 'MediaItem' || this.$route.name === 'MediaBundle') {
        if (typeof crumb === 'object') {
          let mediaPathArr = []
          for (const item of this.crumbs) {
            if (typeof item === 'object') {
              mediaPathArr.push(item.id)
              if (item.id === crumb.id) break
            }
          }
          const mediaPath = mediaPathArr.join('/')
          return { name: 'MediaLibrary', params: { id: this.$route.params.id, path: mediaPath}}
        }
      }
      return crumbPath.concat(crumb);
    },
    getFormattedCrumbName (crumb) {
      let name = this.getCrumbName(crumb)
      const keywords = ['publishing', 'edit', 'medialibrary', 'apps', 'advertising', 'users', 'transcodes', 'iv', 'pushnotifications', 'organizations', 'new', 'analytics']
      if (keywords.includes(name.toLowerCase())) {
        name = this.$t(`Breadcrumbs.${name.toLowerCase()}`)
      }
      return name?.length > 25 ? name.substring(0,20) + '...' : name
    },
    getCrumbName: function(crumb) {
      if (crumb === this.$store.state.venom.org.id) {
        return this.$store.state.venom.org.name || this.$route.params.id;
      }
      if ( this.$route.name === 'AppEdit' ) {
        const app = this.$store.state.venom.org.orgApps?.nodes.find( app => app.id === crumb );
        if (app) {
          return app.name;
        }
      }
      if ( this.$route.name === 'InteractiveVideoDetail' ) {
        const iv = this.$store.state.venom.selected?.interactive;
        if ( iv?.id === crumb ) {
          return iv.name;
        }
      }
      if ( this.$route.name === 'Collections' || this.$route.name === 'Content' || this.$route.name === 'ContentDetails' ) {
        if (typeof crumb === 'object') {
          return crumb.name
        }
        if (crumb.toLowerCase() === 'content') {
          return (this.org.content?.i18nName?.all?.length && this.org.content?.i18nName?.all[0].value) || crumb 
        }
        const prop = this.getProperty( crumb );
        const collection = this.getCollection( crumb );
        const contentItem = this.getContentItem( crumb );
        const content = this.getContent(crumb) 
        if (prop) {
          return prop.i18nName.localized.value;
        }
        if (collection) {
          return collection.ref.i18nName.localized.value;
        }
        if (contentItem) {
          return contentItem.primaryLanguage.title;
        }
        if (content) {
          return content.i18nName.localized.value
        }
      }
      if( this.$route.name === 'MediaLibrary' ) {
        if( this.$store.state.venom.org.currentFolderAndParents ) {
          let parents = this.$store.state.venom.org.currentFolderAndParents.parents;
          const folder = parents.find( folder => folder.id === crumb );
          if( folder)
            return folder.name;
          if ( crumb === this.crumbs[this.crumbs.length-1] && this.crumbs.length > 4 && !this.$store.state.venom.org.currentFolderAndParents?.folder?.isRoot)
            return this.$store.state.venom.org.currentFolderAndParents.folder?.name;
        }
        return crumb;
      }
      
      if ( this.$route.name === 'MediaBundle' ) {
        if (typeof crumb === 'object') {
          return crumb.name
        }
        const bundle = this.getMediaBundle(crumb);
        if(bundle)
          return bundle.node.name;
        return crumb;
      }
      if ( this.$route.name === 'MediaItem' ) {
        if (typeof crumb === 'object') {
          return crumb.name
        }
        const bundle = this.getMediaBundle(crumb);
        if(bundle)
          return bundle.node.title;

        const transcodedMedia = this.getMediaItemFromBundle(crumb);
        if( transcodedMedia )
          return transcodedMedia;
        
        return crumb;
      }
      if (this.$route.name === 'OrgUserProfile') {
        return this.getUserName(crumb)
      }

      return crumb;
    },
    getFolder(crumb) {
      return this.$store.state.venom.org.mediaFolders.find( folder => folder.id === crumb )
    },
    getProperty( crumb ) {
      let property = this.$store.state.venom.org.properties?.propertiesConnection?.nodes?.find( prop => prop.id === crumb )
      if(property){
        return property
      }else if(this.$store.state.venom.org.collections.id === crumb) {
        return this.$store.state.venom.org.collections;
      }
      return '';
    },
    getCollection( crumb ) {
      return this.$store.state.venom.org.collections?.items?.nodes?.find( collection => collection.id === crumb ) || '';
    },
    getContentItem( crumb ) {
      if(this.$store.state.venom.org.contentItem.id === crumb)
      return  this.$store.state.venom.org.contentItem;

      return '';
    },
    getMediaBundle( crumb ) {
      return this.$store.state.venom.org.mediaLibraryItems?.find( bundle => bundle.node.id === crumb );
    },
    getMediaItemFromBundle ( crumb ) {
      const bundles = this.$store.state.venom.org.mediaLibraryItems?.filter(bundle => bundle.node.__typename === 'MediaBundle');
      if (!bundles?.length) return crumb
      for (const bundle of bundles ){
        const item = bundle.node.media.find( item => item.id === crumb )
        if (item)
          return item.title;
      }
    },
    getContent (crumb) {
      const { content } = this.$store.state.venom.org
      if (content?.id === crumb)
        return content
    },
    getUserName (crumb) {
      const users = this.$store.state.venom.org.users || this.$store.state.venom.org.dashboardUsersSummary;
      if (users?.length) {
        const user = users.find(item => item.id === crumb)
        if (user) {
          return `${user.firstName} ${user.lastName}`
        } else {
          return crumb
        }
      } else return crumb
    },
    setCrumbs () {
      if ( this.crumbs && this.$route.name === 'Collections' ) {
        this.crumbs.splice(-2,2)
      }
      if ( this.crumbs && this.$route.name === 'Content' ) {
        this.crumbs.splice(-2,1)
        const content = this.getContent(this.crumbs[this.crumbs.length - 1])
        const parents = content?.parents?.length ? content.parents.slice(0, content.parents.length - 2).reverse() : []
        if (parents?.length) {
          this.crumbs.splice(this.crumbs.length - 1, 0, ...parents)
        }
      }
      if ( this.crumbs && (this.$route.name === 'MediaBundle' || this.$route.name === 'MediaItem' )) {
        this.crumbs.splice(4,1)
        const mediaItem = this.getMediaBundle(this.crumbs[this.crumbs?.length -1 ])
        if (mediaItem) {
          const parents = mediaItem?.node?.parents?.slice(0, mediaItem.node.parents.length - 3).reverse()
          this.crumbs.splice(4, 0, ...parents)
        }
      }
      if (this.crumbs && (this.$route.name === 'ContentDetails')) {
        //when content details page open,then initially it have old values for collection.
        //so not to show old value for collections this chekc has been placed.
        let contentId = this.$route.params.contentId;
        let storedContentId = this.contentItem.id;
        if(contentId === storedContentId){
          const parents = this.org?.contentItem?.parents?.slice(0, this.org.contentItem.parents.length - 2).reverse();
          if (parents?.length)
          this.crumbs.splice(5, 1, ...parents)
        }else{
          this.crumbs.splice(5, 1, "");
        }
        
      }
      if (this.organizations?.length === 1) {
        this.crumbs.splice(1, 1)
      }
    }
  },
  watch: {
    routePath: function( newValue ) {
      if ( newValue ) {
        this.crumbs = this.routePath.split('/');
      }
      if( this.$route.name === 'Dashboard') {
        this.crumbs.splice( 3, 1);
      }
      this.setCrumbs()
    },
    content: {
      handler () {
        this.crumbs = this.routePath.split('/');
        this.setCrumbs()
      },
      deep: true,
      immediate: true
    },
    contentItem:{
      handler () {
        this.crumbs = this.routePath.split('/');
        this.setCrumbs()
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.crumbs = this.routePath.split('/');
    if( this.$route.name === 'Dashboard') {
      this.crumbs.splice( 3, 1);
    }
    this.setCrumbs()
  }
}
</script>

<style lang="scss" scoped>
.uk-breadcrumb {
  text-align: left;
}
.cumb-link:focus {
  color: var(--app-primary-color);
}
.cumb-link:hover {
  color:var(--muted-text-color)
}
</style>
