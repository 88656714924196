<template>
  <div id="sidebar" style="max-height:100vh">
      <div class="uk-cover-container" tabindex="0" style="padding:23px 23px;">
        <router-link :to="orgRouterLink" class="hover-no-deco uk-text-center uk-padding-remove">
          <ImageOrAbbrevation :src="orgLogo" :alt="org.name" width="200px" height="200px" class="img-abbr" imageClass="uk-border-rounded" />
        </router-link>
      </div>
    <div class="uk-nav-divider"></div>
    <div>
      <ul 
          class="uk-nav-default uk-nav-parent-icon" 
          uk-scrollspy="target: > li.uk-transition-toggle; cls: uk-animation-slide-left; delay: 100; offset-top: 2000; repeat:true; hidden:false"
          uk-nav
      >
       <li
          :class="
            permissibleNavList[0].subRoutes.includes($route.name)
              ? `uk-active ${permissibleNavList[0].class}`
              : permissibleNavList[0].class
          "
        >
          <router-link
            :to="`/organizations/${orgId}/${permissibleNavList[0].href}`"
            class="uk-transition-slide-left-small uk-transition-opaque"
          >
            <span
              v-if="permissibleNavList[0].icon"
              class="uk-margin-small-right"
              :uk-icon="'icon: ' + permissibleNavList[0].icon"
            ></span>
            {{ $t(`Sidebar.${permissibleNavList[0].name}`) }}
          </router-link>
        </li>
        <li
          :class="
            navitem.subRoutes.includes($route.name)
              ? `uk-active ${navitem.class}`
              : navitem.class
          "
          v-for="(navitem, i) in featureSupport && permissibleNavList.slice(1)"
          :key="i"
        >
          <router-link
            v-if="navitem.href"
            :to="`/organizations/${orgId}/${navitem.href}`"
            class="uk-transition-slide-left-small uk-transition-opaque"
          >
            <span
              v-if="navitem.icon"
              class="uk-margin-small-right"
              :uk-icon="'icon: ' + navitem.icon"
            ></span>
            {{ $t(`Sidebar.${navitem.name}`) }}
          </router-link>
          <span v-else>{{ navitem.name }}</span>
        </li>
      </ul>
    </div>
    <div id='spacer' class="uk-margin-medium"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Permissions } from '../../utils/enums';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';

export default {
  name: "Sidebar",
  components: {
    ImageOrAbbrevation
  },
  data() {
    return {
      navlist: [
        {
          class: "uk-transition-toggle",
          name: "Dashboard",
          href: "#",
          icon: "home",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER', 'VIEWER'],
          subRoutes: ['Dashboard']
        },
        {
          class: "uk-transition-toggle",
          name: "MediaLibrary",
          href: "mediaLibrary",
          icon: "video-camera",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER'],
          subRoutes: ['MediaLibrary', 'MediaBundle', 'MediaItem']
        },
        {
          class: "uk-transition-toggle",
          name: "Publishing",
          href: "publishing",
          icon: "album",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER', 'VIEWER'],
          subRoutes: ['Publishing', 'Collections', 'ContentDetails', 'Content']
        },
        {
          class: "uk-transition-toggle",
          name: "Apps",
          href: "apps",
          icon: "grid",
          permissionLevel: ['ADMIN'],
          subRoutes: ['Apps', 'AppEdit']
        },
        {
          class: "uk-transition-toggle",
          name: "InteractiveVideo",
          href: "iv",
          icon: "link",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER'],
          featureName: 'interactiveVideos',
          subRoutes: ['InteractiveVideo', 'InteractiveVideoDetail']
        },
        {
          class: "uk-transition-toggle",
          name: "Advertising",
          href: "advertising",
          icon: "tv",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER'],
          featureName: 'ads',
          subRoutes: ['Advertising']
        },
        {
          class: "uk-transition-toggle",
          name: "Transcodes",
          href: "transcodes",
          icon: "server",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER'],
          featureName: 'transcoding',
          subRoutes: ['Transcodes']
        },
        {
          class: "uk-transition-toggle",
          name: "Analytics",
          href: "analytics",
          icon: "database",
          permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER'],
          featureName: 'analytics',
          subRoutes: ['Analytics']
        },
        {
          class: "uk-transition-toggle",
          name: "Users",
          href: "users",
          icon: "users",
          permissionLevel: ['ADMIN'],
          subRoutes: ['Users', 'InviteUser', 'OrgUserProfile']
        },
        {
          class: "uk-transition-toggle",
          name: "PushNotifications",
          href: "pushNotifications",
          icon: "bolt",
          permissionLevel: ['ADMIN'],
          featureName: 'pushNotifications',
          subRoutes: ['PushNotifications']
        }
      ],
      orgId: this.$route.params.id
    };
  },
  computed: {
    ...mapState({
      viewer: state => state.venom.org.viewer,
      featureSupport: state => state.venom.org.options?.featureSupport,
      org: state => state.venom.org
    }),
    ...mapGetters([
      'organizations'
    ]),
    orgLogo () {
      return this.org.logo ? this.org.logo.url : '';
    },
    permissionSet () {
      const permSet = new Set ()
      this.viewer?.permissions?.forEach(permission => {
        permSet.add(permission.type)
      })
      return permSet
    },
    orgRouterLink () {
      if (this.organizations?.length > 1) {
        return { name: 'Organizations' }
      } else {
        return ''
      }
    },
    permissibleNavList () {
      if (this.viewer?.role === Permissions.ADMIN) return this.navlist
      return this.navlist.filter(navItem => {
        let isAllowed = false
        if (!navItem.permissionLevel && !navItem.featureName) {
          return true
        }
        else if (!navItem.permissionLevel && navItem.featureName) {
          return this.featureSupport && this.featureSupport[navItem.featureName]
        }
        for (let perm of navItem.permissionLevel) {
          if (this.permissionSet.has(perm)) {
            isAllowed = true
            break
          }
        }
        return navItem.featureName && this.featureSupport ? this.featureSupport[navItem.featureName] && isAllowed : isAllowed
      })
    }
  },
  methods: {
    redirect: function(url) {
      if (this.$route.path !== url) this.$router.push(url);
    },
    clearMediaLibraryView ( item ) {
      if( item.name === 'MediaLibrary')
        this.$store.commit('setMediaLibraryView', 'tile' ); //temporary change will find a better solution
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
    background: transparent;
    box-shadow: none;
  }
  .hover-no-deco:hover {
    color: var(--app-primary-color)
  }
  .img-abbr >>> img {
    padding-left: 0px !important;
}
</style>
